<template>
  <common-form
      ref="form"
      url="web/instruction"
      :edit="edit"
      :items="items"
      @success="$emit('success')"></common-form>

</template>

<script>

  export default {
    data () {
      return {
        edit: false,
      }
    },
    computed: {
      items() {
        return [
          {
            type: "image",
            name: "列表图",
            key: "logo",
          },
          {
            type: "input",
            name: "标题",
            key: "title",
            required:true
          },
          {
            type: "input",
            name: "副标题",
            key: "subTitle",
          },
          {
            type: "input",
            name: "简介",
            key: "des",
          },
          {
            type: "number",
            name: "排序",
            key: "sort",
          },
          {
            type: "richtext",
            name: "内容",
            key: "content",
          },
        ]
      }
    },
    methods: {
      show(model = {}) {
        this.edit = model.id > 0
        this.$refs.form.show(model)
      },

    }
  }
</script>
